import * as React from "react";
import "./LanguageSelector.scss";
import { FlagSweden, FlagUnitedKingdom } from "../Icons/Flags";
import i18n from "../../i18n/i18n";

const LanguageSelector = (): React.ReactElement => {

  const changeLanguage = (language: string): void => {
    if (document.firstElementChild) document.firstElementChild.setAttribute("lang", language);
    i18n.changeLanguage(language);
  }

  return (
    <div className="language-selector">
      <div onClick={ () => changeLanguage("sv") } tabIndex={0} role="button" onKeyDown={ (e) => { if (e.key === " " || e.key === "Enter") changeLanguage("sv") } }>
        <FlagSweden width="20px" />
        <div>Svenska</div>
      </div>
      <div onClick={ () => changeLanguage("en") } tabIndex={0} role="button" onKeyDown={ (e) => { if (e.key === " " || e.key === "Enter") changeLanguage("en") } }>
        <FlagUnitedKingdom width="20px" />
        <div>English</div>
      </div>
    </div>
  );
}

export default LanguageSelector;