import React from "react";
import "./FooterMenu.scss";
import { NavigateButton } from "..";

interface IFooterMenuProps {
	onClick?(action: string): void;
	disablePrevious?: boolean;
	disableNext?: boolean;
}

const FooterMenu = (props: IFooterMenuProps): React.ReactElement => {
	return (
		<div className="footerMenu">
			<NavigateButton disabled={ props.disablePrevious } isPrevious onClick={() => { !!props.onClick && props.onClick("prev") }} />
			<NavigateButton disabled={ props.disableNext } onClick={() => { !!props.onClick && props.onClick("next") }} />
		</div>
	);
}

export default FooterMenu;
