import * as React from 'react';
import { RouteComponentProps, StaticContext } from "react-router";
import Firebase from '.';

const FirebaseContext = React.createContext({} as Firebase);

export const withFirebase = (Component: (
	React.FunctionComponent<any> |
	React.ComponentClass<any, any> |
	React.ComponentClass<RouteComponentProps<any, StaticContext, any>, any> |
	React.FunctionComponent<RouteComponentProps<any, StaticContext, any>>
)) => (props: any) => (
	<FirebaseContext.Consumer>
		{ firebase => <Component { ...props } firebase={ firebase } /> }
	</FirebaseContext.Consumer>
);

export default FirebaseContext;