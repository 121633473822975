import * as React from 'react';
import { RouteComponentProps, StaticContext } from "react-router";

const AuthenticationContext = React.createContext({} as firebase.User | null);

export const WithAuthentication = (Component: (
	React.FunctionComponent<any> |
	React.ComponentClass<any, any> |
	React.ComponentClass<RouteComponentProps<any, StaticContext, any>, any> |
	React.FunctionComponent<RouteComponentProps<any, StaticContext, any>>
)) => (props: any) => (
	<AuthenticationContext.Consumer>
		{ loggedInUser => <Component { ...props } loggedInUser={ loggedInUser } /> }
	</AuthenticationContext.Consumer>
);

export default AuthenticationContext;
