import React, { useState, ChangeEvent, useEffect } from 'react';
import Firebase, { withFirebase } from '../../components/Firebase';
import { AdminPage, LoadingSpinner } from '../../components';
import { IAreaData } from '../../components/Firebase/firebase';

interface IAdminAddEditCategoryViewProps {
  firebase: Firebase;
  history: { goBack(): void };
  match: { params: { id: string } };
}

const AdminAddEditCategoryView = (props: IAdminAddEditCategoryViewProps): React.ReactElement => {

  const [areas, setAreas] = useState<IAreaData[] | undefined>(undefined);
  const [swedishTitle, setSwedishTitle] = useState<string>("");
  const [englishTitle, setEnglishTitle] = useState<string>("");
  const [newCategoryAreas, setNewCategoryAreas] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const { id } = props.match.params;
  const { firebase, history } = props;

  useEffect(() => {
    setEditId(id);
    firebase.getAreas().then(areas => {
      setAreas(areas);
    });
    if (id) {
      setIsLoading(true);
      firebase.getCategory(id).then((category: {id: string; se: string; en: string; areas: string[]}) => {
        setSwedishTitle(category.se as string);
        setEnglishTitle(category.en as string);
        setNewCategoryAreas(category.areas ? category.areas.map((areaId:string) => { return areaId }) : []);
        setIsLoading(false);
      });
    }
  }, [firebase, id]);

  const cancel = () => {
    history.goBack();
  }

  const save = () => {
    setIsLoading(true);
    if (!editId) {
      firebase.saveCategory(swedishTitle, englishTitle, newCategoryAreas).then(() => {
        setIsLoading(false);
        history.goBack();
      });
    } else {
      firebase.editCategory(editId, swedishTitle, englishTitle, newCategoryAreas).then(() => {
        setIsLoading(false);
        history.goBack();
      });
    }
  }

  const onChangeSwedishTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setSwedishTitle(e.currentTarget.value);
  }

  const onChangeEnglishTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setEnglishTitle(e.currentTarget.value);
  }

  const setCategoryArea = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setNewCategoryAreas([...newCategoryAreas, e.currentTarget.value]);
    } else {
      setNewCategoryAreas(newCategoryAreas.filter(area => { return area !== e.currentTarget.value }));
    }
  }

  return (
    <AdminPage activeItem={1}>
      {!isLoading && areas !== undefined ?
        <>
          { editId ? <h2>Ändra kategori</h2> : <h2>Lägg till kategori</h2> }
          <div>
            <label>Svensk titel</label>
            <input type="text" placeholder="Svensk titel" value={swedishTitle} onChange={onChangeSwedishTitle} />
            <label>Engelsk titel</label>
            <input type="text" placeholder="Engelsk titel" value={englishTitle} onChange={onChangeEnglishTitle} />
            <div>
              {areas && areas.map((area) => {
                return (
                  <div key={area.id}>
                    <input type="checkbox" id="chkBox" value={area.id} onChange={setCategoryArea} checked={ newCategoryAreas.includes(area.id) }/>
                    <label htmlFor="chkBox">{area.se}</label>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="form-button-container">
            <button className="form-button" onClick={cancel}>Avbryt</button>
            <button className="form-button" onClick={save} disabled={swedishTitle.length === 0 || newCategoryAreas.length === 0}>Spara</button>
          </div>
        </>
        :
        <LoadingSpinner />
      }
    </AdminPage>
  );
}

export default withFirebase(AdminAddEditCategoryView);