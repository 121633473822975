import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link, RouteProps } from "react-router-dom";
import "./App.scss";
import { Header, Logotype } from "./components/";
import {
	SelectAreaView,
	SelectCategoryView,
	CardView,
	AdminView,
	StartPageView,
	FeedbackView,
	AdminAreasView,
	AdminCategoriesView,
	AdminQuestionsView,
	AdminUsersView,
	AdminFeedbackView,
	AdminAddEditAreaView,
	AdminAddEditCategoryView,
	AdminAddEditQuestionView
} from "./pages/";
import AuthenticationContext from "./components/Firebase/authorization";
import Firebase, { withFirebase } from "./components/Firebase";
import "./i18n/i18n";
import LanguageSelector from "./components/LanguageSelector/LanguageSelector";
import InfoBefore from "./pages/InfoBefore";
import { useTranslation } from "react-i18next";

interface IAppProps {
	firebase: Firebase
}

const App = (props: IAppProps) => {

	const [loggedInUser, setLoggedInUser] = useState<firebase.User | null>(null);
	const { t } = useTranslation();

	props.firebase.auth.onAuthStateChanged(authUser => {
		setLoggedInUser(authUser)
	});

	return (
		<AuthenticationContext.Provider value={loggedInUser}>
			<Router>
				<Route path="/" component={(props: RouteProps) => {
					const isAdmin = (props.location as unknown as Location).pathname.includes("admin");
					return (
						<div className={`App ${isAdmin ? "" : "app-layout"}`}>
							<Header>
								<LanguageSelector />
								<Link to="/"><Logotype width="20vw" alt={t("ALT-LOGOTYPE")} /></Link>
							</Header>
							<main>
								<Route exact path="/" component={StartPageView} />
								<Route exact path="/area" component={SelectAreaView} />
								<Route exact path="/area/:areaId" component={SelectCategoryView} />
								<Route exact path="/area/:areaId/category/:categoryId" component={CardView} />
								<Route exact path="/feedback" component={FeedbackView} />
								<Route exact path="/info-before" component={InfoBefore} />
								<Route exact path="/admin" component={AdminView} />
								<Route exact path="/admin/areas" component={AdminAreasView} />
								<Route exact path="/admin/areas/edit/:id?" component={AdminAddEditAreaView} />
								<Route exact path="/admin/categories" component={AdminCategoriesView} />
								<Route exact path="/admin/categories/edit/:id?" component={AdminAddEditCategoryView} />
								<Route exact path="/admin/questions" component={AdminQuestionsView} />
								<Route exact path="/admin/questions/edit/:id?" component={AdminAddEditQuestionView} />
								<Route exact path="/admin/users" component={AdminUsersView} />
								<Route exact path="/admin/feedback" component={AdminFeedbackView} />
							</main>
						</div>)
				}} />
			</Router>
		</AuthenticationContext.Provider>
	);
}

export default withFirebase(App);