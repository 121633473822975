import React from "react";

interface ILogotypeProps {
	width: string;
	alt: string;
}

const Logotype = (props: ILogotypeProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={ props.width } viewBox="0 0 1111 452" role="img">
			<title>{props.alt}</title>
			<defs><clipPath id="a" transform="translate(-.5 .45)">
				<path fill="none" d="M.5 0H1111v451.09H.5z" /></clipPath>
			</defs>
			<g clipPath="url(#a)">
				<path fill="#fff" d="M0 196.23v210.56h154.52v-39.02H42.03v-45.21h100.12v-39.01H42.03v-48.31h112.49v-39.01H0zm379.81 185.79l34.61 41.18-31.21 28.8-40.48-47.69a113.67 113.67 0 0 1-37.08 6.19c-60.57 0-109.4-48.3-109.4-109s48.83-109 109.4-109 109.4 48.3 109.4 109a109 109 0 0 1-35.23 80.51m-74.17-10.84c38.32 0 66.75-31.28 66.75-69.67s-28.43-69.67-66.75-69.67-66.75 31.28-66.75 69.67 28.43 69.67 66.75 69.67m544.98-6.5H753.9l-17.3 42.12h-45.1l91.17-210.57h39.25l90.86 210.57h-44.84zm-15.45-37.47l-33.07-80.51-33.07 80.51zM1111 367.77v39.02H956.48V196.23h43.15v171.54H1111zm-453.92-55.46c0 61.93-37.7 98.47-94.25 98.47-56.25 0-93.95-36.54-93.95-98.47V196.45h42v115.86c0 35.92 20.7 59.14 51.92 59.14s52.23-23.22 52.23-59.14V196.45h42z" /><path fill="#3ac7b4" d="M417.33 114.47h-13.28V29.12l-38.13 63.46h-8.73l-38.47-63.79.33 85.68h-13.27V0h10.08l45.69 76.42L407.25 0h10.08v114.47zm117.76-32.02h-59l-14.11 32h-14.25L499.3 0h12.6l51.6 114.45h-14.3zm-5.38-12l-24.21-54.8-24.17 54.88zm99.79-8.84l-22.18 23.4v29.45h-13.27V0h13.27v66.66L670.99 0h16.3l-48.72 51.84 54.6 62.62h-17.14L629.5 61.61zm105.49-49.32v39.22h60.3V63.8h-60.3v38.38h66.7v12.29h-79.97V0h79.97v12.29h-66.7z" />
			</g>
		</svg>
	);
}

export default Logotype;
