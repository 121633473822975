import React from 'react';
import "./Header.scss";

interface IHeaderProps {
	children: any;
}

const Header = (props: IHeaderProps): React.ReactElement => {
	return (
		<div className="headerContainer">
			{props.children}
		</div>
	);
}

export default Header;
