import * as React from "react";
import IFlagIconProp from "./IFlagIconProps";

export default (props: IFlagIconProp): React.ReactElement => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 512 512" role="img" lang="en">
			<title>Switch to english</title>
			<path fill="#000065" d="M189.2168 8.8183594c-32.758 8.8299996-62.91222 23.9935626-89.07422 44.1015626l89.07422 89.074218zm133.56445 0V141.99609l89.07617-89.074215c-26.163-20.109-56.31717-35.273516-89.07617-44.1035156zM52.919922 100.14258c-20.109 26.163-35.272563 56.31717-44.1015626 89.07617H141.99609zm406.160158 0l-89.07422 89.07617h133.17578c-8.829-32.758-23.99356-62.91317-44.10156-89.07617zM8.8183594 322.7832c8.8299996 32.758 23.9935626 62.91417 44.1015626 89.07618l89.074218-89.07618zm361.1875006 0l89.07422 89.07618c20.108-26.162 35.27256-56.31818 44.10156-89.07618zm-180.78711 47.22266l-89.07617 89.07226c26.163 20.109 56.31817 35.27352 89.07617 44.10352zm133.56445 0v133.17578c32.758-8.83 62.91222-23.99356 89.07422-44.10156z" />
			<path fill="#d80027" d="M256 0c-11.319 0-22.46063.74496875-33.39062 2.1679688V222.60742H2.1679688C.74496875 233.53842 0 244.683 0 256c0 11.319.74496875 22.46062 2.1679688 33.39062H222.60742v220.44141C233.53842 511.25503 244.681 512 256 512c11.317 0 22.46062-.74397 33.39062-2.16797V289.39258h220.44141C511.25503 278.46158 512 267.319 512 256c0-11.317-.74497-22.46063-2.16797-33.39062H289.39258V2.1679688C278.46158.74496875 267.317 0 256 0zm164.58594 59.933594L322.7832 157.73633v31.48242L437.01953 74.980469c-5.252-5.254-10.74159-10.265875-16.43359-15.046875zM74.980469 74.980469c-5.254 5.252-10.264875 10.741593-15.046875 16.433593l97.802736 97.804688h31.48047v-.002zM189.21484 322.7832L74.980469 437.01953c5.252 5.254 10.741593 10.26488 16.433593 15.04688l97.802738-97.80274V322.7832zm133.56836 0l114.23633 114.23633c5.254-5.252 10.26488-10.74159 15.04688-16.43359l-97.80079-97.80274z" />
		</svg>
	)
}