import React, { useState, useEffect } from "react";
import Firebase, { withFirebase } from '../../components/Firebase';
import { AdminPage, TrashIcon, LoadingSpinner, AddIcon, EditIcon } from "../../components";
import { IQuestionData, ICategoryData } from "../../components/Firebase/firebase";
import { Link } from "react-router-dom";

interface IAdminQuestionsViewProps {
  firebase: Firebase;
  history: { push(uri: string): void }
}

const AdminQuesionsView = (props: IAdminQuestionsViewProps): React.ReactElement => {
  const [questions, setQuestions] = useState<IQuestionData[] | undefined>(undefined);

  useEffect(() => {
    props.firebase.getAllQuestions().then(questions => {
      setQuestions(questions);
    });
  }, [props.firebase]);

  const removeQuestion = (id: string) => {
    props.firebase.removeQuestion(id).then(() => {
      props.firebase.getAllQuestions().then(questions => {
        setQuestions(questions);
      });
    });
  };

  const editQuestion = (id: string) => {
    props.history.push(`/admin/questions/edit/${id}`);
  }

  return (
    <AdminPage activeItem={2}>
      <section className="editSection">
        {questions ?
          <>
            <h2>Frågor</h2>
            <table>
              <tbody>
                <tr>
                  <th>Fråga</th>
                  <th colSpan={3}>Kategorier</th>
                </tr>
                {questions && questions.map(question => (
                  <tr key={question.id} className="editItem">
                    <td>
                      <div><strong>{question.se}</strong></div>
                      <div><small>{question.en}</small></div>
                      {question.subquestions && question.subquestions.se && question.subquestions.se.length > 0 &&
                        <div className="subQuestions">

                          {question.subquestions.se.map((subquestion: string, index: number) => {
                            return <div key={index}><strong>{subquestion}</strong></div>
                          })}

                        </div>
                      }
                      {question.subquestions && question.subquestions.en && question.subquestions.en.length > 0 &&
                        <div className="subQuestions">
                          {question.subquestions.en.map((subquestion: string, index: number) => {
                            return <div key={index}>{subquestion}</div>
                          })}
                        </div>
                      }
                    </td>
                    <td>
                      <small className="noBreak">
                        <span className="capitalized">
                          {question.categories && (question.categories as ICategoryData[]).map(category => category.se).join(", ")}
                        </span>
                      </small>
                    </td>
                    <td><button className="iconButton" onClick={() => editQuestion(question.id)}><EditIcon /></button></td>
                    <td><button className="iconButton" onClick={() => removeQuestion(question.id)}><TrashIcon /></button></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-icon-container">
              <Link to="/admin/questions/edit"><AddIcon /></Link>
            </div>
          </>
          :
          <LoadingSpinner />
        }
      </section>
    </AdminPage>
  );
}

export default withFirebase(AdminQuesionsView);