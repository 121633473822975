import React from 'react';

interface IAddIconProps {
	width?: string;
	fill?: string;
}

const AddIcon = (props: IAddIconProps) => {
	return (
    <svg xmlns="http://www.w3.org/2000/svg" width={ props.width || "20px" } viewBox="0 0 92 93">
      <path fill={ props.fill || "#000000" } d="M46 4a42 42 0 100 85 42 42 0 000-85zm27 49H53v20c0 2-2 3-4 3h-6c-2 0-3-1-3-3V53H20c-2 0-4-2-4-4v-6c0-2 2-4 4-4h20V20c0-2 1-4 3-4h6c2 0 4 2 4 4v20h20c2 0 3 1 3 3v6c0 2-1 4-3 4z"/>
    </svg>
	);
}

export default AddIcon;
