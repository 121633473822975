import * as React from "react";

interface IStarIconProp {
  width?: string;
  height?: string;
  filled?: boolean;
  onClick?(): void;
  alt: string;
}

const StarIcon = (props: IStarIconProp) => {
  const { width = "20px", height, filled = false, onClick, alt } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54" width={width} height={height} onClick={onClick}>
      <title>{alt}</title>
      <path strokeWidth="2" stroke="black" fill={filled ? "#efce4a" : "#ffffff"} d="M27 1l8 17 19 3-14 13 4 19-17-9-17 9 3-19L0 21l19-3z" />
    </svg>
  )
};

export default StarIcon;