import React from 'react';
import { IShapeProps } from "./IShapeProps";

const ShapeBackgroundLarge = (props: IShapeProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 200 113">
			<path fill={props.fill} d="M85.149542 112.66683c-11.733086-.14753-23.325286-.48058-25.760446-.7402-15.821116-1.6867-26.144106-4.28134-35.420606-8.90273C10.859949 96.493424 3.6088002 87.287294.83196634 73.649474c-1.44010264-7.07271-.95265976-23.15857 1.01431746-33.47415 1.3516094-7.08826 4.6893414-15.70861 7.9550786-20.54543C14.706734 12.364604 22.098746 7.0441732 31.26934 4.1782432c4.25363-1.32932 10.802858-2.53603 16.828976-3.10081C50.911022.81380326 64.02917.58040326 79.695094.51523326c14.733024-.0613 32.402296-.25088 39.265036-.42128 34.6947-.86164 55.13975 4.16565994 67.73751 16.65608074 9.49729 9.41645 13.93516 23.01754 13.22978 40.54647-.40779 10.13309-2.39751 22.70524-4.45641 28.15772-6.08654 16.118776-23.46115 25.013036-53.16549 27.216026-5.93947.44049-21.91818.43953-57.155978-.002z" />
		</svg>
	);
}

export default ShapeBackgroundLarge;
