import React from 'react';
import "./LoadingSpinner.scss";


interface ILoadingSpinnerProps {
}

const LoadingSpinner = (props: ILoadingSpinnerProps): React.ReactElement => {
	return (
		<div className="loaderContainer">
			<div className="loader"></div>
		</div>
	);
}

export default LoadingSpinner;