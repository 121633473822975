import React, { FormEvent, useState, ChangeEvent } from "react";
import "./LoginForm.scss";
import { BubbleButton } from "..";
import Firebase, { withFirebase } from "../Firebase";

interface ILoginFormProps {
	firebase: Firebase;
	onSubmit(user: firebase.User | null): void;
}

const LoginForm = (props: ILoginFormProps): React.ReactElement => {

	const [email, setEmail] = useState<string | undefined>(undefined);
	const [password, setPassword] = useState<string | undefined>(undefined);

	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

	const onEmailChanged = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.currentTarget.value);
	}

	const onPasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.currentTarget.value);
	}

	const submitForm = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!!email && !!password) {
			props.firebase.doSignInUser(email, password).then(response => {
				props.onSubmit(response.user);
			}).catch(error => {
				setErrorMessage(error.code);
			});
		}
		return false;
	}

	const getTranslatedErrorMessage = (code: string): string => {
		switch (code) {
			case "auth/wrong-password":
			case "auth/user-not-found":
				return "Kunde inte hitta användaren eller ett felaktigt lösenord angavs";
			case "auth/user-disabled":
				return "Användaren är avstängd";
			case "auth/invalid-email":
				return "Email-adressen som angavs är inte giltig";
			default:
				return "Okänt fel"
		}
	}

	return (
		<div>
			<h1 className="title">Logga in</h1>
			<form className="loginForm" onSubmit={submitForm}>
				<div className="content">
					<label htmlFor="inEmail">Email-adress</label>
					<input type="email" id="inEmail" name="email" onChange={onEmailChanged} required />
					<label htmlFor="inPassword">Lösenord</label>
					<input type="password" id="inPassword" name="password" onChange={onPasswordChanged} required />
				</div>
				<BubbleButton disabled={!email || !password} text="Logga in" isSubmitButton={true} />
			</form>
			{errorMessage && <div className="errorMessage">{getTranslatedErrorMessage(errorMessage)}</div>}
		</div>
	);
}

export default withFirebase(LoginForm);