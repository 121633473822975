import React, { useState, useEffect, ChangeEvent } from 'react';
import Firebase, { withFirebase } from '../../components/Firebase';
import { AdminPage, LoadingSpinner, TrashIcon } from '../../components';
import { IAreaData } from '../../components/Firebase/firebase';

interface IAdminAddEditAreaViewProps {
  firebase: Firebase;
  history: { goBack(): void };
  match: { params: { id: string } };
}

interface IStartQuestionItem {
  se: string;
  en: string;
}

const AdminAddEditAreaView = (props: IAdminAddEditAreaViewProps): React.ReactElement => {

  const [swedishTitle, setSwedishTitle] = useState<string>("");
  const [englishTitle, setEnglishTitle] = useState<string>("");
  const [newStartQuestionSwedish, setNewStartQuestionSwedish] = useState("");
  const [newStartQuestionEnglish, setNewStartQuestionEnglish] = useState("");
  const [startQuestions, setStartQuestions] = useState<IStartQuestionItem[]>([]);
  const [editId, setEditId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = props.match.params;
  const { firebase, history } = props;

  const cancel = () => {
    history.goBack();
  }

  useEffect(() => {
    setEditId(id);
    if (id) {
      setIsLoading(true);
      firebase.getArea(id).then((areaItem: IAreaData) => {
        setSwedishTitle(areaItem.se as string);
        setEnglishTitle(areaItem.en as string);
        setStartQuestions(areaItem.startQuestions as IStartQuestionItem[] || [])
        setIsLoading(false);
      });
    }
  }, [id, firebase])

  const save = () => {
    setIsLoading(true);
    if (!editId) {
      firebase.saveArea(swedishTitle, englishTitle, startQuestions).then(() => {
        setIsLoading(false);
        history.goBack();
      });
    } else {
      firebase.editArea(editId, swedishTitle, englishTitle, startQuestions).then(() => {
        setIsLoading(false);
        history.goBack();
      })
    }
  }

  const onChangeSwedishTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setSwedishTitle(e.currentTarget.value);
  }

  const onChangeEnglishTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setEnglishTitle(e.currentTarget.value);
  }

  const addStartQuestion = (): void => {
    setStartQuestions(state => [...state, { se: newStartQuestionSwedish, en: newStartQuestionEnglish }]);
    setNewStartQuestionSwedish("");
    setNewStartQuestionEnglish("");
  }

  const removeQuestion = (index: number): void => {
    setStartQuestions(state => { 
      const stateCopy = [...state];
      stateCopy.splice(index, 1);
      return stateCopy; 
    });
  }

  return (
    <AdminPage activeItem={0}>
      {!isLoading ?
        <>
          {!!editId ? <h2>Ändra område</h2> : <h2>Lägg till område</h2>}
          <div>
            <label>Svensk titel</label>
            <input type="text" placeholder="Svensk titel" value={swedishTitle} onChange={onChangeSwedishTitle} />
            <label>Engelsk titel</label>
            <input type="text" placeholder="Engelsk titel" value={englishTitle} onChange={onChangeEnglishTitle} />
          </div>
          <div className="add-start-question">
            <h3>Lägg till uppvärmningsfråga</h3>
            <input type="text" placeholder="Uppvärmingsfråga på svenska" value={newStartQuestionSwedish} onChange={(e) => { setNewStartQuestionSwedish(e.currentTarget.value) }} />
            <input type="text" placeholder="Uppvärmingsfråga på engelska" value={newStartQuestionEnglish} onChange={(e) => { setNewStartQuestionEnglish(e.currentTarget.value) }} />
            <button className="form-button" onClick={addStartQuestion} disabled={newStartQuestionSwedish.length === 0 || newStartQuestionEnglish.length === 0}>Lägg till uppvärmningsfråga</button>
            { startQuestions &&
            <div className="spaceAbove">
              {startQuestions.map((questions, index) => (
                <div key={index}><strong>{questions.se}</strong>&nbsp;{questions.en}&nbsp;<span onClick={() => removeQuestion(index)}><TrashIcon /></span></div>
              ))}
            </div>
}
          </div>
          <div className="form-button-container">
            <button className="form-button" onClick={cancel}>Avbryt</button>
            <button className="form-button" onClick={save} disabled={swedishTitle.length === 0}>Spara</button>
          </div>
        </>
        :
        <LoadingSpinner />
      }
    </AdminPage>
  );
}

export default withFirebase(AdminAddEditAreaView);