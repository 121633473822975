import React from 'react';
import "./QuestionCard.scss";
import i18n from '../../i18n/i18n';
import { IQuestionData } from '../Firebase/firebase';

const QuestionCard = (props: IQuestionData) => {
	let subQuestions: [] = [];
	if (props.subquestions) {
		if (i18n.language === "sv" && props.subquestions.se) {
			subQuestions = props.subquestions.se;
		} else {
			if (props.subquestions.en) {
				subQuestions = props.subquestions.en;
			}
		}
	}
	return (
		<div className="questionCard">
			{i18n.language === "sv" ? props.se : props.en}
			{subQuestions.length > 0 &&
				<div className="subQuestions">
					{subQuestions.map((subQuestion: string, index: number) => {
						return <div key={index}>{subQuestion}</div>
					})}
				</div>
			}
		</div>
	);
}

export default QuestionCard;
