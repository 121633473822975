import React, { useState, useEffect } from 'react';
import Firebase, { withFirebase } from '../../components/Firebase';
import { AdminPage } from "../../components";

interface IAdminFeedbackViewProps {
  firebase: Firebase;
}

const AdminFeedbackView = (props: IAdminFeedbackViewProps): React.ReactElement => {
  const [feedbacks, setFeedbacks] = useState<[]>([])

  useEffect(() => {
    props.firebase.getFeedback().then((data) => {
      setFeedbacks(data);
    })
  }, [props.firebase]);

  return <AdminPage activeItem={4}>
    <h2>lämnad feedback</h2>
    <table>
      <tbody>
        <tr>
          <th>Kommentar</th>
          <th>Betyg</th>
          <th>Tid</th>
        </tr>
        {feedbacks && feedbacks.length > 0 && feedbacks.sort( (f1: {timeStamp: number }, f2: {timeStamp: number }) => (f2.timeStamp - f1.timeStamp) ).map((feedback: { comment: string; rating: number; timeStamp: Date }, index: number) =>
          <tr key={index}>
            <td>{feedback.comment}</td>
            <td>{feedback.rating}</td>
            <td>{new Date(feedback.timeStamp).toLocaleString()}</td>
          </tr>
        )}
      </tbody>
    </table>
  </AdminPage>;
}

export default withFirebase(AdminFeedbackView);