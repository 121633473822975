import React from 'react';
import "./Button.scss";

interface IButtonProps {
	children: any;
	onClick?(): void;
}

const Button = (props: IButtonProps): React.ReactElement => {
	return (
		<button className="button" onClick={ props.onClick }>{props.children}</button>
	);
}

export default Button;
