import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';

interface IAdminViewProps {
	history: { push(uri: string): void }
}

const AdminView = (props: IAdminViewProps): React.ReactElement => {

	const onSubmitHandler = (user: firebase.User | null): void => {
		props.history.push("/admin/areas");
	}

	return (
		<div>
			<LoginForm onSubmit={ onSubmitHandler }/>
		</div>
	);
}

export default AdminView;
