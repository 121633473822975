import React from 'react';

interface IEditIconProps {
	width?: string;
	fill?: string;
}

const EditIcon = (props: IEditIconProps) => {
	return (
    <svg xmlns="http://www.w3.org/2000/svg" width={ props.width || "20px" } viewBox="0 0 400 400">
      <path fill={ props.fill || "#000000" } d="M371 251c-6 0-10 4-10 10v89c0 16-14 30-30 30H50c-17 0-30-14-30-30V89c0-16 13-30 30-30h89a10 10 0 100-20H50C22 39 0 62 0 89v261c0 27 22 50 50 50h281c27 0 50-23 50-50v-89c0-6-5-10-10-10zm0 0"/>
      <path fill={ props.fill || "#000000" } d="M377 13a45 45 0 00-64 0L135 192l-3 4-23 85a10 10 0 0012 12l85-23 4-3L388 89c18-18 18-46 0-64zM156 198L302 52l47 47-146 146zm-9 19l38 38-52 14zM374 75l-10 10-47-47 10-10c10-10 26-10 35 0l12 11c10 10 10 26 0 36zm0 0"/>
    </svg>
	);
}

export default EditIcon;
