import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appID: process.env.REACT_APP_APP_ID
};

export interface IAreaData { id: string; se?: string; en?: string, startQuestions?: {se: string, en: string}[] };
export interface ICategoryData { id: string; se?: string; en?: string; areas?: IAreaData[] };
export interface IQuestionData {
	id: string;
	se?: string;
	en?: string;
	categories?: ICategoryData[] | string[];
	subquestions?: {
		en?: [];
		se?: [];
	}
};

class Firebase {

	// TODO: Implement cache

	auth: app.auth.Auth;
	db: app.firestore.Firestore;

	cachedAreas: IAreaData[] | undefined;

	constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		this.db = app.firestore();
	}

	doSignInUser = async (email: string, password: string): Promise<app.auth.UserCredential> => {
		return await this.auth.signInWithEmailAndPassword(email, password);
	}

	doSignOutUser = () => this.auth.signOut();

	doSignInUserAnonymously = async (): Promise<app.auth.UserCredential> => {
		return await this.auth.signInAnonymously();
	}

	getAreas = async (): Promise<IAreaData[]> => {
		if (!this.cachedAreas) {
			const snapshot = await this.db.collection("areas").get();
			this.cachedAreas = snapshot.docs.map((doc: {
				id: string;
				data(): firebase.firestore.DocumentData;
			}) => {
				const { se, en, startQuestions } = doc.data();
				const { id } = doc;
				return { id, se, en, startQuestions };
			});
		}
		return this.cachedAreas;
	}

	getArea = async (id: string): Promise<IAreaData> => {
		const doc = await this.db.collection("areas").doc(id).get();
		const { se, en, startQuestions } = doc.data() as { en: string; se: string, startQuestions: {se: string, en: string}[] };
		return { id, se, en, startQuestions };
	}

	editArea = async (id: string, se: string, en: string, startQuestions: {se: string, en: string}[]): Promise<any> => {
		return this.db.collection("areas").doc(id).set({ se, en, startQuestions }).then(ref => {
			this.cachedAreas = undefined;
			return ref;
		}).catch(error => {
			console.error(error);
			return error;
		});
}

saveArea = async (se: string, en: string, startQuestions:{se: string, en: string}[] ): Promise<any> => {
	return this.db.collection("areas").add({ se, en, startQuestions }).then(ref => {
		this.cachedAreas = undefined;
		return ref;
	}).catch(error => {
		console.error(error);
		return error;
	});
}

removeArea = async (areaId: string): Promise<any> => {
	return this.db.collection("areas").doc(areaId).delete().then(() => {
		this.cachedAreas = undefined;
		return null;
	}).catch(error => {
		console.error(error);
		return error;
	})
}

editCategory = async (id: string, se: string, en: string, areas: string[]): Promise<any> => {
	return this.db.collection("categories").doc(id).set({ se, en, areas }).then(ref => {
		return ref;
	}).catch(error => {
		console.error(error);
		return error;
	})
}

saveCategory = async (se: string, en: string, areas: string[]): Promise<any> => {
	return this.db.collection("categories").add({ se, en, areas }).then(ref => {
		return ref;
	}).catch(error => {
		console.error(error);
		return error;
	})
}

saveQuestion = async (se: string, en: string, newSubQuestions: { en: string; se: string; }[], categories: string[]): Promise<any> => {
	const subquestionsSwedish = newSubQuestions.map(subQuestion => subQuestion.se);
	const subquestionsEnglish = newSubQuestions.map(subQuestion => subQuestion.en);
	return this.db.collection("questions").add({
		se,
		en,
		subquestions: { se: subquestionsSwedish, en: subquestionsEnglish },
		categories
	}).then(ref => {
		return ref;
	}).catch(error => {
		console.error(error);
		return error;
	})
}

editQuestion = async (id: string, se: string, en: string, newSubQuestions: { en: string; se: string; }[], categories: string[]): Promise<any> => {
	const subquestionsSwedish = newSubQuestions.map(subQuestion => subQuestion.se);
	const subquestionsEnglish = newSubQuestions.map(subQuestion => subQuestion.en);
	return this.db.collection("questions").doc(id).set({
		se,
		en,
		subquestions: { se: subquestionsSwedish, en: subquestionsEnglish },
		categories
	}).then(ref => {
		return ref;
	}).catch(error => {
		console.error(error);
		return error;
	})
}

removeCategory = async (categoryId: string): Promise<any> => {
	return this.db.collection("categories").doc(categoryId).delete().then(() => {
		return null;
	}).catch(error => {
		console.error(error);
		return error;
	})
}

getAllCategories = async (): Promise<ICategoryData[]> => {
	return await this.db.collection("areas").get().then(async areaSnapshot => {
		return await this.db.collection("categories").get().then(snapShot => {
			return snapShot.docs.map((doc: {
				id: string;
				data(): firebase.firestore.DocumentData;
			}) => {
				const { se, en, areas: areaIds } = doc.data();
				const { id } = doc;

				const areas = areaSnapshot.docs
					.filter(areaDoc => { return (areaIds as string[]).includes(areaDoc.id); })
					.map(
						(doc: {
							id: string;
							data(): firebase.firestore.DocumentData;
						}) => {
							const { se, en } = doc.data();
							const { id } = doc;
							return { id, se, en };
						}
					)
				return { id, se, en, areas };
			});
		})
	})
}

getCategories = async (areaId: string): Promise<ICategoryData[]> => {
	const snapshot = await this.db.collection("categories").where("areas", "array-contains", areaId).get();
	return snapshot.docs.map((doc: {
		id: string;
		data(): firebase.firestore.DocumentData;
	}) => {
		const { se, en } = doc.data();
		const { id } = doc;
		return { id, se, en };
	});
}

getCategory = async (id: string): Promise<{id: string; se: string; en: string; areas: string[]}> => {
	const doc = await this.db.collection("categories").doc(id).get();
	const { se, en, areas } = doc.data() as { en: string; se: string; areas: string[] };
	return { id, se, en, areas };
}

getAllQuestions = async (): Promise<IQuestionData[]> => {
	return await this.db.collection("categories").get().then(async categorySnapshot => {
		return await this.db.collection("questions").get().then(snapShot => {
			return snapShot.docs.map((doc: {
				id: string;
				data(): firebase.firestore.DocumentData;
			}) => {
				const { categories: categoryIds, en, se, subquestions } = doc.data();
				const { id } = doc;

				const categories = categorySnapshot.docs
					.filter(categoryDoc => { return (categoryIds as string[]).includes(categoryDoc.id); })
					.map(
						(doc: {
							id: string;
							data(): firebase.firestore.DocumentData;
						}) => {
							const { se, en } = doc.data();
							const { id } = doc;
							return { id, se, en };
						}
					)
				return { id, se, en, categories, subquestions };
			});
		})
	})
}

getQuestions = async (categoryId: string): Promise<IQuestionData[]> => {
	const snapshot = await this.db.collection("questions").where("categories", "array-contains", categoryId).get();
	return snapshot.docs.map((doc: {
		id: string;
		data(): firebase.firestore.DocumentData;
	}) => {
		const { se, en, subquestions } = doc.data();
		const { id } = doc;
		return { id, se, en, subquestions };
	});
}

getQuestion = async (id: string): Promise<IQuestionData> => {
	const doc = this.db.collection("questions").doc(id);
	return (await doc.get()).data() as IQuestionData;
}

removeQuestion = async (questionId: string): Promise<any> => {
	return await this.db.collection("questions").doc(questionId).delete().then(() => {
		return null
	}).catch(error => {
		return error;
	})
}

leaveFeedback = async (rating: number, comment: string): Promise<any> => {
	return await this.db.collection("feedback").add({ rating, comment, timeStamp: Date.now() });
}

getFeedback = async (): Promise<any> => {
	return await this.db.collection("feedback").get().then( snapShot => {
		const data = snapShot.docs.map( doc => { return doc.data() });
		return data;
	})
}

addUser = async (email: string, password: string): Promise<any> => {
	return await this.auth.createUserWithEmailAndPassword(email, password);
}
}

export default Firebase;