import * as React from "react";
import { SuperManImage, BubbleButton } from "../components";
import Firebase, { withFirebase } from "../components/Firebase";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../i18n/i18n";

interface ISelectAreaViewProps {
  history: { push(path: string): void }
  firebase: Firebase;
}

const SelectAreaView = (props: ISelectAreaViewProps): React.ReactElement => {

  const { t } = useTranslation();

  const start = () => {
    props.firebase.doSignInUserAnonymously().then(() => {
      props.history.push("/area");
    });
  }

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === "sv" ? "en" : "sv");
    if (document.firstElementChild) document.firstElementChild.setAttribute("lang", i18n.language);
  }

  return (
    <div className="startPageView">
      <SuperManImage width="200px" />
      <h1>{t("TITLE")}</h1>
      <p>{t("TEXT-INTRO")}</p>
      <div className="spaceAbove">
        <Link to="/info-before">{t("INFO-BEFORE-WE-START")}</Link>
      </div>
      <div className="spaceAbove">
        <BubbleButton text={t("BUTTON-START-TALK")} onClick={start} />
      </div>
      <div className="spaceAbove">
        <Link to="/" onClick={changeLanguage} lang={i18n.language === "sv" ? "en" : "sv"} hrefLang={i18n.language === "sv" ? "en" : "sv"}>{t("LINK-CHANGE-LANGUAGE")}</Link>
      </div>
      <div className="sponsorMessage">
        <div>
          <Trans i18nKey="COPYRIGHT-INFO">
            Spelet är en del av <a href="https://www.makeequal.se/" rel="noopener noreferrer" target="_blank">Make Equals</a> projekt <a href="http://alltviintepratarom.se/" rel="noopener noreferrer" target="_blank">Allt vi inte pratar om</a> som tagits fram med stöd av av <a href="https://postkodstiftelsen.se/" rel="noopener noreferrer" target="_blank">Svenska Postkodsstiftelsen</a>
          </Trans>
        </div>
      </div>
    </div>
  )
}

export default withFirebase(SelectAreaView);