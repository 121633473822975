import React, { useEffect, useState } from "react";
import "./pages.scss";
import { SuperManImage, SelectList, SelectListItem, LoadingSpinner, BubbleButton } from "../components";
import Firebase, { withFirebase } from '../components/Firebase';
import { ICategoryData } from '../components/Firebase/firebase';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';

interface ISelectCategoryViewProps {
	match: { params: { areaId: string } };
	history: { push(path: string): void };
	firebase: Firebase;
}

const SelectCategoryView = (props: ISelectCategoryViewProps): React.ReactElement => {

	const { areaId } = props.match.params;
	const { push } = props.history;
	const [categories, setCategories] = useState<ICategoryData[] | undefined>(undefined);
	const { t } = useTranslation();

	useEffect(() => {
		props.firebase.getCategories(areaId).then(data => { setCategories(data); });
	}, [props.firebase, areaId]);

	const onClickHandler = (categoryId: string): void => {
		push(`/area/${areaId}/category/${categoryId}`);
	}

	const changeAreaHandler = (): void => {
		push("/area");
	}

	const orderNumbers = (inputCategory: string | undefined): string => {
		if (inputCategory === undefined)
			return "";
		const prefix = inputCategory.substring(0, 3);
		const prefixAsNumber = parseInt(prefix);
		return prefixAsNumber > 0 && prefixAsNumber < 10 ? "0" + inputCategory : inputCategory;
	}

	const sortList = (categories: ICategoryData[]): ICategoryData[] => {
		if (i18n.language === "sv") {
			return categories.sort((cat1, cat2) => { return (orderNumbers(cat1.se) as string).localeCompare(orderNumbers(cat2.se) as string, "sv-se") as number });
		} else {
			return categories.sort((cat1, cat2) => { return (orderNumbers(cat1.en) as string).localeCompare(orderNumbers(cat2.en) as string, "en-us") as number });
		}
	}

	return (
		<div>
			<SuperManImage width="200px" />
			{!!categories ?
				categories.length > 0 ?
					<>
						<SelectList title={t("TITLE-PICK-CATEGORY")} onClick={onClickHandler}>
							{sortList((categories as ICategoryData[])).map((category: ICategoryData) => {
								return <SelectListItem key={category.id} id={category.id}>{i18n.language === "sv" ? category.se : category.en}</SelectListItem>
							})}
						</SelectList>
						<div className="spaceAbove">
							<BubbleButton text={t("BUTTON-CHANGE-AREA")} onClick={changeAreaHandler} />
						</div>
					</>
					:
					<div className="errorMessage">{t("NO-CATEGORIES-FOUND")}</div>
				:
				<LoadingSpinner></LoadingSpinner>
			}
		</div>
	);
}

export default withFirebase(SelectCategoryView);
