import React from 'react';
import "./SelectList.scss";
import { Button } from '..';

interface ISelectListItemProps {
	children: any;
	id: string;
	onClick?(id: string): void;
}

const SelectListItem = (props: ISelectListItemProps): React.ReactElement => {
	return (
		<li><Button onClick={ () => { props.onClick && props.onClick(props.id) } }>{ props.children }</Button></li>
	);
}

export default SelectListItem;
