import React, { useState, useEffect } from 'react';
import Firebase, { withFirebase } from '../../components/Firebase';
import { AdminPage, TrashIcon, LoadingSpinner, AddIcon, EditIcon } from "../../components";
import { ICategoryData } from '../../components/Firebase/firebase';
import { Link } from 'react-router-dom';

interface IAdminCategoriesViewProps {
	firebase: Firebase;
	history: { push(uri: string): void }
}

const AdminCategoriesView = (props: IAdminCategoriesViewProps): React.ReactElement => {
	const [categories, setCategories] = useState<ICategoryData[] | undefined>(undefined);

	useEffect(() => {
		props.firebase.getAllCategories().then(categories => {
			setCategories(categories);
		});
	}, [props.firebase]);

	const removeCategory = (id: string) => {
		props.firebase.removeCategory(id).then(() => {
			props.firebase.getAllCategories().then(categories => {
				setCategories(categories);
			})
		})
	}

	const editCategory = (id: string): void => {
		props.history.push(`/admin/categories/edit/${id}`);
	}

	return (
		<AdminPage activeItem={1}>
			<section className="editSection">

				{categories ?
					<>
						<h2>Kategorier</h2>
						<table>
							<tbody>
								<tr>
									<th>Kategori</th>
									<th colSpan={3}>Områden</th>
								</tr>
								{categories && categories.map(category => (
									<tr key={category.id} className="editItem">
										<td className="capitalized">
											<div><strong>{category.se}</strong></div>
											<div><small>{category.en}</small></div>
										</td>
										<td>
											<small className="noBreak">
												<span className="capitalized">
													{category.areas && category.areas.map(area => area.se).join(", ")}
												</span>
											</small>
										</td>
										<td><button className="iconButton" onClick={() => editCategory(category.id)}><EditIcon /></button></td>
										<td><button className="iconButton" onClick={() => removeCategory(category.id)}><TrashIcon /></button></td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="add-icon-container">
							<Link to="/admin/categories/edit"><AddIcon /></Link>
						</div>
					</>
					:
					<LoadingSpinner />}
			</section>
		</AdminPage>
	);
}

export default withFirebase(AdminCategoriesView);