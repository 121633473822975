import * as React from "react";
import IFlagIconProp from "./IFlagIconProps";

export default (props: IFlagIconProp): React.ReactElement => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 512 512" lang="sv" role="img">
			<title>Byt till svenska</title>
			<path fill="#ffda44" d="M512 256a256 256 0 0 1-256 256A256 256 0 0 1 0 256 256 256 0 0 1 256 0a256 256 0 0 1 256 256z" />
			<path fill="#0052b4" d="M256 0c-19.114 0-37.73334 2.1139375-55.65234 6.0859375V222.60938h309.48437C493.46903 97.002375 386.067 0 256 0zM133.56445 31.126953C63.270453 69.480953 12.950969 139.83238 2.1679688 222.60938H133.56445V31.126953zM2.1679688 289.39062C12.950969 372.16762 63.270453 442.51809 133.56445 480.87109V289.39062H2.1679688zm198.1796912 0v216.52344C218.26666 509.88606 236.886 512 256 512c130.067 0 237.46903-97.00237 253.83203-222.60938H200.34766z" />
		</svg>
	)
}