import React from "react";
import "./SelectList.scss";
import { SelectListItem } from "..";

interface ISelectListProps {
	children: any;
	title: string;
	onClick(id: string): void;
}

const SelectList = (props: ISelectListProps): React.ReactElement => {
	return (
		<>
			<h1>{props.title}</h1>
			<ul className="selectList">
				{props.children && props.children.map((item: React.ReactElement, index: number) => {
					return <SelectListItem key={index} id={item.props.id} onClick={props.onClick}>{item.props.children}</SelectListItem>
				})}
			</ul>
		</>
	);
}

export default SelectList;
