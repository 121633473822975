import React from 'react';
import "./BubbleButton.scss";
import ShapeBackgroundLarge from '../ShapeBackgrounds/ShapeBackgroundLarge';

interface IBubbleButtonProps {
	onClick?(): void;
	text: string;
	disabled?: boolean;
	isSubmitButton?: boolean;
}

const BubbleButton = (props: IBubbleButtonProps): React.ReactElement => {
	return (
		<button type={ !!props.isSubmitButton ? "submit" : "button" } className="bubbleButton" onClick={ !!props.onClick ? props.onClick : undefined } disabled={ props.disabled }>
			<ShapeBackgroundLarge width="120px" />
			<div>{props.text}</div>
		</button>
	);
}

export default BubbleButton;
