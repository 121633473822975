import React, { useEffect, useState, useRef } from "react";
import { SuperManImage, SelectList, SelectListItem, LoadingSpinner, BubbleButton } from "../components";
import Firebase, { withFirebase } from '../components/Firebase';
import { IAreaData } from '../components/Firebase/firebase';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';

interface ISelectAreaViewProps {
	history: { push(uri: string): void; }
	firebase: Firebase;
}

const SelectAreaView = (props: ISelectAreaViewProps): React.ReactElement => {

	const [areas, setAreas] = useState<IAreaData[] | undefined>(undefined);
	const { t } = useTranslation();
	const isMounted = useRef(true);
	const [selecedArea, setSelectedArea] = useState<IAreaData | undefined>(undefined);

	useEffect(() => {
		props.firebase.getAreas().then(data => { if (isMounted.current) setAreas(data) });
		return () => {
			isMounted.current = false;
		}
	}, [props.firebase]);

	const onClickHandler = (id: string) => {
		if (areas) {
			const chosenArea = areas.find((item) => item.id === id);
			setSelectedArea(chosenArea);
			if (chosenArea && (!chosenArea.startQuestions || chosenArea.startQuestions.length === 0)) {
				props.history.push(`/area/${id}`);
			}

		}
	}

	const orderNumbers = (inputArea: string | undefined): string => {
		if (inputArea === undefined)
			return "";
		const prefix = inputArea.substring(0, 3);
		const prefixAsNumber = parseInt(prefix);
		return prefixAsNumber > 0 && prefixAsNumber < 10 ? "0" + inputArea : inputArea;
	}

	const sortList = (areas: IAreaData[]): IAreaData[] => {
		if (i18n.language === "sv") {
			return areas.sort((area1, area2) => { return (orderNumbers(area1.se) as string).localeCompare(orderNumbers(area2.se) as string, "sv-se") as number });
		} else {
			return areas.sort((area1, area2) => { return (orderNumbers(area1.en) as string).localeCompare(orderNumbers(area2.en) as string, "en-us") as number });
		}
	}

	const goToSelectCategory = () => {
		if (selecedArea)
			props.history.push(`/area/${selecedArea.id}`);
	}

	return (
		<div>
			<SuperManImage width="200px" />
			{!!areas && !selecedArea ?
				areas.length > 0 ?
					<SelectList title={t("TITLE-PICK-AREA")} onClick={onClickHandler}>
						{sortList((areas as IAreaData[])).map((area: IAreaData) => {
							return <SelectListItem key={area.id} id={area.id}>{i18n.language === "sv" ? area.se : area.en}</SelectListItem>
						})}
					</SelectList>
					: <div className="errorMessage">{t("NO-AREAS-FOUND")}</div>
				:
				!selecedArea ? <LoadingSpinner></LoadingSpinner> : (
					<div>
						<h1>{t("START-QUESTIONS-HEADLINE")}</h1>
						<p>{t("START-QUESTIONS-INFO")}</p>
						<div className="start-questions">
							{selecedArea.startQuestions && selecedArea.startQuestions.map((quesiton, index) => {
								return <div key={index} className={ index > 0 ? "spaceAbove" : ""}>{i18n.language === "sv" ? quesiton.se : quesiton.en}</div>
							})}
						</div>
						<div className="spaceAbove">
							<BubbleButton text={t("BUTTON-CONTINUE")} onClick={ goToSelectCategory } />
						</div>
					</div>
				)
			}
		</div>
	);
}

export default withFirebase(SelectAreaView);
