import React, { useState, useEffect, useRef } from 'react';
import Firebase, { withFirebase } from '../../components/Firebase';
import { AdminPage, TrashIcon, LoadingSpinner, AddIcon, EditIcon } from "../../components/";
import { IAreaData } from '../../components/Firebase/firebase';
import { Link } from 'react-router-dom';

interface IAdminAreasViewProps {
	firebase: Firebase;
	history: { push(uri: string): void }
}

const AdminAreasView = (props: IAdminAreasViewProps): React.ReactElement => {
	const [areas, setAreas] = useState<IAreaData[] | undefined>(undefined);
	const isMounted = useRef(true);

	useEffect(() => {
		props.firebase.getAreas().then(areas => {
			if (isMounted.current) {
				setAreas(areas);
			}
		});
		return () => {
			isMounted.current = false;
		}
	}, [props.firebase])

	const removeArea = (id: string): void => {
		props.firebase.removeArea(id).then((response) => {
			props.firebase.getAreas().then(areas => {
				setAreas(areas);
			});
		});
	}

	const editArea = (id: string): void => {
		props.history.push(`/admin/areas/edit/${id}`);
	}

	const orderNumbers = (inputArea: string | undefined ): string => {
		if (inputArea === undefined)
			return "";
		const prefix = inputArea.substring(0,3);
		const prefixAsNumber = parseInt(prefix);
		return prefixAsNumber > 0 && prefixAsNumber < 10 ? "0" + inputArea : inputArea;
	}

	const sortList = (areas: IAreaData[]): IAreaData[] => {
		return areas.sort((area1, area2) => { return (orderNumbers(area1.se) as string).localeCompare(orderNumbers(area2.se) as string, "sv-se") as number });
	}

	return (
		<AdminPage activeItem={0}>
			<section className="editSection">
				{areas ?
					<>
						<h2>Områden</h2>
						<table>
							<tbody>
								<tr>
									<th colSpan={3}>Område</th>
								</tr>
								{areas && sortList(areas).map(area => {
									return (
										<tr key={area.id} className="editItem">
											<td>
												<div><strong>{area.se}</strong></div>
												<div><small>{area.en}</small></div>
												{ area.startQuestions &&
												<div>
													<h4>Uppvärmningsfrågor:</h4>
													{ area.startQuestions.map((question, index) => {
														return (<div key={index} className="spaceAbove">
															<div>{question.se}</div>
															<div><small>{question.en}</small></div>
															</div>)
													})}
												</div>
												}
											</td>
											<td><button className="iconButton" onClick={() => editArea(area.id)}><EditIcon /></button></td>
											<td><button className="iconButton" onClick={() => removeArea(area.id)}><TrashIcon /></button></td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<div className="add-icon-container">
							<Link to="/admin/areas/edit"><AddIcon /></Link>
						</div>
					</>
					:
					<LoadingSpinner />
				}
			</section>
		</AdminPage>
	);
}

export default withFirebase(AdminAreasView);