import * as React from "react";
import { useState } from "react";
import { StarIcon, Button, BubbleButton } from "../components";
import { useTranslation } from "react-i18next";
import Firebase, { withFirebase } from "../components/Firebase";
import { Link } from "react-router-dom";

interface IFeedbackViewProps {
  firebase: Firebase;
}

const FeedbackView = (props: IFeedbackViewProps): React.ReactElement => {
  const { firebase } = props;
  const { t } = useTranslation();
  const [rating, setRating] = useState(3);
  const [feedbackDone, setFeedbackDone] = useState(false);
  const [comment, setComment] = useState("");


  const postFeedback = () => {
    firebase.leaveFeedback(rating, comment.substr(0, 1024)).then(() => {
      setFeedbackDone(true);
    }).catch(() => {
      setFeedbackDone(true);
    });
  }

  const getAltRating = (rating: number) => {
    return t("RATING-SET-START-" + rating);
  }

  const setRatingWithKeys = (e: React.KeyboardEvent, newRating: number): void => {
    if (e.key === " " || e.key === "Enter") {
      setRating(newRating);
    }
  }

  return (
    <div className="feedbackView">
      <h1>{t("TITLE-FEEDBACK")}</h1>
      {!feedbackDone ? (
        <>
          <div className="rating">
            <div tabIndex={0} role="button" onKeyDown={e => { setRatingWithKeys(e, 1) }}><StarIcon width="40px" onClick={() => { setRating(1) }} filled={rating >= 1} alt={`${t("RATING-STAR-1")} ${getAltRating(rating)}`} /></div>
            <div tabIndex={0} role="button" onKeyDown={e => { setRatingWithKeys(e, 2) }}><StarIcon width="40px" onClick={() => { setRating(2) }} filled={rating >= 2} alt={`${t("RATING-STAR-2")} ${getAltRating(rating)}`} /></div>
            <div tabIndex={0} role="button" onKeyDown={e => { setRatingWithKeys(e, 3) }}><StarIcon width="40px" onClick={() => { setRating(3) }} filled={rating >= 3} alt={`${t("RATING-STAR-3")} ${getAltRating(rating)}`} /></div>
            <div tabIndex={0} role="button" onKeyDown={e => { setRatingWithKeys(e, 4) }}><StarIcon width="40px" onClick={() => { setRating(4) }} filled={rating >= 4} alt={`${t("RATING-STAR-4")} ${getAltRating(rating)}`} /></div>
            <div tabIndex={0} role="button" onKeyDown={e => { setRatingWithKeys(e, 5) }}><StarIcon width="40px" onClick={() => { setRating(5) }} filled={rating >= 5} alt={`${t("RATING-STAR-5")} ${getAltRating(rating)}`} /></div>
          </div>
          <div className="spaceAbove" style={{ textAlign: "left" }}>
            <label htmlFor="commentId">{t("PLACEHOLDER-COMMENT")}:</label>
            <textarea
              rows={6}
              style={{ width: "100%", resize: "none", marginTop: "1rem", padding: "1rem", border: "solid 1px #5cb8b2", borderRadius: "6px" }}
              onChange={(e) => { setComment(e.currentTarget.value) }}
              id="commentId"
            >
            </textarea>
          </div>
          <Button onClick={postFeedback}>{t("BUTTON-SUBMIT-FEEDBACK")}</Button>
        </>
      )
        :
        <>
          <div>{t("INFO-THANKS-FOR-FEEDBACK")}</div>
          <div className="spaceAbove">
            <Link to="/">
              <BubbleButton text={t("BUTTON-RESTART")} />
            </Link>
          </div>
        </>
      }
    </div>
  )
};

export default withFirebase(FeedbackView);