import React from 'react';
import "./AdminPage.scss";
import { WithAuthentication } from '../Firebase/authorization';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import Firebase, { withFirebase } from '../Firebase';

interface IAdminPageProps {
  children: any;
  loggedInUser: firebase.User | null;
  firebase: Firebase;
  activeItem?: number | undefined;
}

const AdminPage = (props: IAdminPageProps): React.ReactElement => {

  const logout = () => {
    props.firebase.doSignOutUser();
  }

  if (props.loggedInUser === null || props.loggedInUser.isAnonymous) {
    return (
      <div>
        Du måste vara <Link to="/admin">inloggad</Link> för att komma åt den här sidan
			</div>
    )
  }

  const email = props.loggedInUser.email;

  const getItem = (index: number, uri: string, name: string): React.ReactNode => {
    return <li className={`${props.activeItem === index ? "activeItem" : ""}`}><Link to={uri}>{name}</Link></li>;
  }

  return (
    <div>
      <h3>{email}</h3>
      <div className="admin-menu">
        <ul>
          {getItem(0, "/admin/areas", "Områden")}
          {getItem(1, "/admin/categories", "Kategorier")}
          {getItem(2, "/admin/questions", "Frågor")}
          {getItem(3, "/admin/users", "Användare")}
          {getItem(4, "/admin/feedback", "Feedback")}
          <li><Link to="/admin" onClick={logout}>Logga ut</Link></li>
        </ul>
      </div>
      <div className="admin-page-container adminPage">
        {props.children}
      </div>
    </div>
  );
}

export default compose<IAdminPageProps, { activeItem?: number | undefined }>(withFirebase, WithAuthentication)(AdminPage);