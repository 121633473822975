import React from "react";
import "./NavigateButton.scss";
import ShapeBackgroundMedium from "../ShapeBackgrounds/ShapeBackgroundMedium";
import { useTranslation } from "react-i18next";

interface INavigateButtonProps {
	onClick(): void;
	isPrevious?: boolean;
	disabled?: boolean;
}

const NavigateButton = (props: INavigateButtonProps): React.ReactElement => {

	const { t } = useTranslation();

	return (
		<button className="nextButton" onClick={props.onClick} disabled={props.disabled}>
			<ShapeBackgroundMedium width="100px" />
			{!props.isPrevious && <div>{t('BUTTON-NEXT')}&nbsp;&gt;</div>}
			{props.isPrevious && <div>&lt;&nbsp;{t('BUTTON-PREV')}&nbsp;</div>}
		</button>
	);
}

export default NavigateButton;
