import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { BubbleButton } from "../components";

interface IInfoBeforeProps {
  history: { push(uri: string): void }
}

const InfoBefore = (props: IInfoBeforeProps): React.ReactElement => {

  const { t } = useTranslation();

  const goBack = () => {
    props.history.push("/")
  }

  return (
    <div className="info-before-view">
      <div><Trans i18nKey="INFO-BEFORE-VIEW.INTRODUCTION">intro text</Trans></div>
      <div><Trans i18nKey="INFO-BEFORE-VIEW.BEFORE-THE-TALK">before the conversation text</Trans></div>
      <div><Trans i18nKey="INFO-BEFORE-VIEW.DURING-THE-TALK">during the conversation text</Trans></div>
      <div><Trans i18nKey="INFO-BEFORE-VIEW.AFTER-THE-TALK">after the conversation text</Trans></div>
      <div><Trans i18nKey="INFO-BEFORE-VIEW.OUTRO">outro text</Trans></div>
      <p>
        <strong>
          {t("INFO-BEFORE-VIEW.MORE-INFO")}
        </strong>

      </p>
      <div>
        <div><a href="http://alltviintepratarom.se/" target="_blank" rel="noopener noreferrer">alltviintepratarom.se/</a></div>
        <div><a href="http://www.makeequal.se" target="_blank" rel="noopener noreferrer">www.makeequal.se</a></div>
        <div><a href="http://www.killmiddag.se" target="_blank" rel="noopener noreferrer">www.killmiddag.se</a></div>
      </div>
      <div className="spaceAbove">
        <BubbleButton onClick={goBack} text={`< ${t("BUTTON-BACK")}`} />
      </div>
    </div>
  )
}

export default InfoBefore;