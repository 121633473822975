import React, { useState, ChangeEvent } from "react";
import Firebase, { withFirebase } from '../../components/Firebase';
import { AdminPage, BubbleButton } from "../../components";

interface IAdminUsersViewProps {
  firebase: Firebase;
}

const AdminUsersView = (props: IAdminUsersViewProps): React.ReactElement => {
  const [addedUser, setAddedUser] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const addUser = () => {
    props.firebase.addUser(email, password).then((res: any) => {
      setAddedUser(email);
    }).catch((error: { code: string }) => {
      if (error.code === "auth/email-already-in-use") {
        setError(`Användaren ${email} finns redan`);
      } else {
        setError("Någonting gick fel, försök igen");
      }
    })
  }

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  }

  const changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  }

  return (
    <AdminPage activeItem={3}>
      <h2>Lägg till admin-användare</h2>
      {!addedUser ?
        <>
          {error && <p>{error}</p>}
          <div><label>Email</label><input type="text" placeholder="Email" value={email} onChange={changeEmail} /></div>
          <div><label>Password</label><input type="password" placeholder="Password" value={password} onChange={changePassword} /></div>
          <div className="text-center spaceAbove"><BubbleButton onClick={addUser} text="Lägg till" /></div>
        </>
        :
        <div>Användaren {addedUser} är nu tillagd</div>
      }
    </AdminPage>
  );
}

export default withFirebase(AdminUsersView);