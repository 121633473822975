import React, { useState, useEffect, useCallback } from "react";
import { QuestionCard, LoadingSpinner, BubbleButton } from "../components";
import FooterMenu from "../components/FooterMenu/FooterMenu";
import Firebase, { IQuestionData } from "../components/Firebase/firebase";
import { withFirebase } from "../components/Firebase";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from '../i18n/i18n';

interface ICardViewProps {
	match: { params: { areaId: string; categoryId: string; } }
	history: { push(path: string): void }
	firebase: Firebase;
}

const CardView = (props: ICardViewProps): React.ReactElement => {

	const { areaId, categoryId } = props.match.params;
	const [questions, setQuestions] = useState<IQuestionData[] | undefined>(undefined);
	const [currentQuestionId, setCurrentQuestionId] = useState<number>(0);
	const { t } = useTranslation();

	const orderNumbers = (inputArea: string | undefined): string => {
		if (inputArea === undefined)
			return "";
		const prefix = inputArea.substring(0, 3);
		const prefixAsNumber = parseInt(prefix);
		return prefixAsNumber > 0 && prefixAsNumber < 10 ? "0" + inputArea : inputArea;
	}

	const sortList = useCallback((questions: IQuestionData[]): IQuestionData[] => {
		if (i18n.language === "sv") {
			return questions.sort((q1, q2) => { return (orderNumbers(q1.se) as string).localeCompare(orderNumbers(q2.se) as string, "sv-se") as number });
		} else {
			return questions.sort((q1, q2) => { return (orderNumbers(q1.en) as string).localeCompare(orderNumbers(q2.en) as string, "en-us") as number });
		}
	}, []);

	useEffect(() => {
		props.firebase.getQuestions(categoryId).then(data => {
			setQuestions(sortList(data));
		});
	}, [props.firebase, categoryId, sortList]);

	const goToNext = () => {
		setCurrentQuestionId(currentQuestionId + 1);
	}

	const goToPrev = () => {
		setCurrentQuestionId(currentQuestionId - 1);
	}

	const goPrevOrNext = (action: string): void => {
		if (action === "prev") {
			goToPrev();
		}
		else if (action === "next") {
			goToNext();
		}
	}

	const changeArea = (): void => {
		const { push } = props.history;
		push("/area");
	}

	const changeCategory = (): void => {
		const { push } = props.history;
		push(`/area/${areaId}`);
	}

	return (
		<>
			{!!questions ?
				questions.length > 0 ?
					<>
						<div className="navigationButtonsContainer">
							<BubbleButton text={t("BUTTON-CHANGE-AREA")} onClick={changeArea} />
							<BubbleButton text={t("BUTTON-CHANGE-CATEGORY")} onClick={changeCategory} />
						</div>
						<QuestionCard {...questions[currentQuestionId]} />
						<div className="questionNumber">({t("WORD-QUESTION")} {currentQuestionId + 1} {t("WORD-OF")} {questions.length})</div>
						{currentQuestionId === questions.length - 1 &&
								<div className="spaceAbove">
									<Link to="/feedback">{t("LINK-LEAVE-FEEDBACK")}</Link>
								</div>
							}
						<FooterMenu onClick={goPrevOrNext} disablePrevious={!(currentQuestionId > 0)} disableNext={!(currentQuestionId < questions.length - 1)} />
					</>
					:
					<div className="errorMessage">{t("ERROR-NO-QUESTIONS-YET")}</div>
				:
				<LoadingSpinner></LoadingSpinner>
			}
		</>
	);
}

export default withFirebase(CardView);
