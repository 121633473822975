import React from 'react';
import { IShapeProps } from "./IShapeProps";

const ShapeBackgroundMedium = (props: IShapeProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 70 49">
			<path fill={props.fill} d="M33.219224 48.845286c-15.612597-.76433-25.4656951-4.34032-30.2243687-10.96937-1.0736241-1.49562-1.9734075-3.46109-2.54151778-5.55169-.426959-1.57116-.45971857-1.95514-.45253798-5.30365.00903851-4.18011.35268256-6.55628 1.39836756-9.66709C3.9158591 9.8665459 9.3803096 4.6907659 17.597307 2.0110359c4.747034-1.54808 8.137579-2.00631 14.880066-2.01096 5.533937-.004 7.611006.16573 12.882153 1.05162 9.872008 1.65912 16.325743 4.85183 20.284499 10.0348601 3.383646 4.43007 4.85395 9.90659 4.206963 15.66988-.53995 4.80984-2.317453 9.86624-4.714124 13.41009-3.777784 5.58603-8.61446 8.07317-16.893713 8.68718-2.678521.19865-10.888057.19403-15.023927-.008z" />
		</svg>
	);
}

export default ShapeBackgroundMedium;
