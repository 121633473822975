export const resources = {
  sv: {
    translation: {
      "TITLE": "Allt vi inte pratar om",
      "TEXT-INTRO": "Alla ska ha rätt till en aktiv fritid. Det skapar välmående, förbättrar hälsan och bygger nätverk. Allt vi inte pratar om – fritid och idrott är ett projekt för att motverka machokultur, sexism och trakasserier inom just fritids- och idrottssektorn. Vårt mål är att förändra normer och attityder som kan vara skadliga och destruktiva. Genom ärliga samtal om jargong, kränkningar och ansvar får deltagarna själva reflektera över roller och normer. Vi vill bygga ett klimat där fler känner sig välkomna och där alla har rätt att vara dem de är.",
      "BUTTON-START-TALK": "Börja prata",
      "TITLE-PICK-AREA": "Välj område",
      "NO-AREAS-FOUND": "Inga områden kunde hittas",
      "NO-CATEGORIES-FOUND": "Det finns inga kategorieri det valda området än.",
      "TITLE-PICK-CATEGORY": "Välj kategori",
      "ERROR-NO-QUESTIONS-YET": "Det finns inga frågor i den här kategorin än",
      "WORD-QUESTION": "fråga",
      "WORD-OF": "av",
      "BUTTON-CHANGE-AREA": "Byt område",
      "BUTTON-CHANGE-CATEGORY": "Byt kategori",
      "BUTTON-NEXT": "Nästa",
      "BUTTON-PREV": "Förra",
      "BUTTON-CONTINUE": "Fortsätt >",
      "LINK-CHANGE-LANGUAGE": "Change to an english version",
      "LINK-LEAVE-FEEDBACK": "Lämna feedback",
      "TITLE-FEEDBACK": "Lämna Feedback",
      "PLACEHOLDER-COMMENT": "Lämna en kommentar här",
      "BUTTON-SUBMIT-FEEDBACK": "Skicka in feedback",
      "INFO-THANKS-FOR-FEEDBACK": "Tack för din feedback",
      "BUTTON-RESTART": "Starta om",
      "COPYRIGHT-INFO": "Spelet är en del av <1>Make Equals</1> projekt <3>Allt vi inte pratar om</3> som tagits fram med stöd av <5>Svenska Postkodsstiftelsen</5>",
      "INFO-BEFORE-WE-START": "Tips och praktisk information",
      "INFO-BEFORE-VIEW": {
        "INTRODUCTION": "<h1>Hej!</h1><p>Välkommen till ett samtal om allt ni annars inte pratar om! Frågorna riktar sig till alla som identifierar sig som killar och män. Med det sagt fungerar det även att köra frågorna i könsblandade grupper eller bara för tjejer eller andra kön. Men då bra att veta att frågorna är utformade- och riktar sig till killar och män och får omformuleras en del.</p><p>Här följer några punkter som kan vara till hjälp innan, under & efter samtalet.</p>",
        "BEFORE-THE-TALK": "<h2>Innan samtalet</h2><ul><li>Kom överens om att det ni pratar om i gruppen sker i förtroende, och att det stannar i rummet.</li><li>Bestäm hur samtalet ska ske. Det kan ske i par eller i grupper om 3-5 personer. Det kan vara att ni sätter er runt ett bord, tar samtalet i omklädningsrummet, under en fika eller online. Gör det som känns bra och som passar er bäst.</li><li>Om samtalet sker i grupp, utse gärna en samtalsledare som ansvarar för samtalet och som ser till att ni exempelvis håller er till ämnet, inte avbryter varandra och behandlar varandra med respekt och omtanke. Ni kan även byta samtalsledare under samtalets gång.</li><li>Kom överens om hur ordet ska fördelas. En kan exempelvis använda sig av samtalsrundor där alla får möjlighet att tala till punkt, använda handuppräckning eller något annat form av system. Det viktiga är att hitta ett sätt som alla känner sig bekväma med. Ett tips är att börja med en runda där alla ges samma möjlighet att svara i lugn och ro, för att sedan släppa ordet fritt och då använda er av handuppräckning.</li></ul>",
        "DURING-THE-TALK": "<h2>Under samtalet</h2><ul><li>Försök att utgå från dig själv och dina erfarenheter. Ibland är det lätt att prata om andra, eller om exempelvis killar och män generellt. Genom att prata i “jag-form” före “man-form” blir det lättare att hålla samtalet till att handla om ens egna erfarenheter.</li><li>När du lyssnar på andras berättelser - försök att utgå från att vi alla gillar olika, och har olika erfarenheter. Alla kommer nödvändigtvis inte tycka som du, eller ha precis samma erfarenheter som du. Försök att inte döma andra, och ifrågasätt inte direkt utan om det är något som känns oklart kan du ställa öppna följdfrågor om det är något som känns oklart. Genom att göra det bidrar du även till ett öppet, tillåtande och inkluderande samtalsklimat.</li><li>Försök att tolka andra snällt. Det krävs både mod och kan vara jobbigt att dela med sig av sina erfarenheter. Genom att tolka snällt utgår vi från att alla vill väl, och att vi genom samtalet utvecklas tillsammans.</li><li>Försök att skapa en stämning där alla känner sig trygga att fråga om det är något en inte förstår eller något som känns oklart. Det handlar inte om att säga “rätt” saker, utan om att dela med sig av erfarenheter, lyssna på andra samt att stötta varandra.</li><li>Ta en fråga i taget och försök att inte stressa. Ni behöver inte gå igenom temana i någon särskild ordning, utan välj det som känns intressant och relevant för er. Utifrån respektive tema kan det sedan vara bra att följa ordningen på frågorna. Med det sagt - är det någon fråga som känns irrelevant eller särskilt svår/jobbig är det bara att ni hoppar över den frågan.</li></ul>",
        "AFTER-THE-TALK": "<h2>Efter samtalet</h2><ul><li>Avsluta gärna samtalet med en runda där alla får berätta kort hur det kändes att ha ett samtal om dessa frågor:</li><ul><li>Hur kändes det att prata om dessa ämnen?</li><li>Var det något som kändes svårt eller jobbigt?</li><li>Vad kommer du att ta med dig från samtalet?</li><li>Vill du lägga till eller kommentera något som kommit upp under samtalet? (hoppa denna om ni har ont om tid).</li><li>Finns det någon eller flera konkreta förändringar som du vill göra i ditt liv som du kommit på under samtalet?</li><li>Skulle du vilja fortsätta att ha den här typen av samtal?</li></ul><li>Om ni vill fortsätta samtalen kan ni starta en gruppchatt eller liknande där ni kan behålla kontakten och planera in nya samtal.</li><li>Påminn gärna varandra om att allt som sagts under samtalet stannar i rummet.</li><li>Uppmana andra att anordna träffar utifrån “Allt vi inte pratar om - fritid och idrott”. Ni kan till exempel lägga upp en bild från träffen på sociala medier under hashtaggen #alltviintepratarom och #killmiddag. Viktigt bara att kolla av med alla så att de vill vara med på bild innan ni delar något. Självklart delas inget om vad som sades under träffen.</li></ul>",
        "OUTRO": "<p><strong>Frågorna riktar sig till alla som identifierar sig som killar/män. Det funkar även att köra frågorna i könsblandade grupper, men det är då bra att veta att frågorna är utformade- och riktar sig till killar och män.</strong></p>",
        "MORE-INFO": "För mer information:"
      },
      "START-QUESTIONS-HEADLINE": "Uppvärmningspåståenden",
      "START-QUESTIONS-INFO": "Alla svarar ja eller nej om påståendet stämmer in på en själv utan att kommentera eller fråga vidare på varandras ställningstagande.",
      "ALT-LOGOTYPE": "Logotyp, Make Equal, till startsidan",
      "ALT-ILLUSTRATED-MAN": "Illustration på man med t-shirt med texten Allt vi inte pratar om",
      "BUTTON-BACK": "Tillbaka",
      "RATING-STAR-1": "Ge appen betyget en stjärna.",
      "RATING-STAR-2": "Ge appen betyget två stjärnor.",
      "RATING-STAR-3": "Ge appen betyget tre stjärnor.",
      "RATING-STAR-4": "Ge appen betyget fyra stjärnor.",
      "RATING-STAR-5": "Ge appen betyget fem stjärnor.",
      "RATING-SET-START-1": "En av fem stjärnor är nu satta.",
      "RATING-SET-START-2": "Två av fem stjärnor är nu satta.",
      "RATING-SET-START-3": "Tre av fem stjärnor är nu satta.",
      "RATING-SET-START-4": "Fyra av fem stjärnor är nu satta.",
      "RATING-SET-START-5": "Fem av fem stjärnor är nu satta.",
    }
  },
  en: {
    translation: {
      "TITLE": "Everything we don’t talk about",
      "TEXT-INTRO": "Everything we don’t talk about - recreation & sports is a project to counteract macho culture, sexism, and harassment in the recreation and sports sector. Our goal is to change norms and attitudes that can be harmful and destructive. Through honest discussions about jargon, abuses, and responsibilities, participants can reflect on roles and norms. We want to create an environment where more people feel welcome and where everyone has the right to be who they are.",
      "BUTTON-START-TALK": "Start talk",
      "TITLE-PICK-AREA": "Pick area",
      "NO-AREAS-FOUND": "No areas could be found",
      "NO-CATEGORIES-FOUND": "There are no categories within the selected area yet.",
      "TITLE-PICK-CATEGORY": "Pick category",
      "ERROR-NO-QUESTIONS-YET": "There are no questions in this category yet",
      "WORD-QUESTION": "question",
      "WORD-OF": "of",
      "BUTTON-CHANGE-AREA": "Change area",
      "BUTTON-CHANGE-CATEGORY": "Change category",
      "BUTTON-NEXT": "Next",
      "BUTTON-PREV": "Previous",
      "BUTTON-CONTINUE": "Continue >",
      "LINK-CHANGE-LANGUAGE": "Byt till den svenska versionen",
      "LINK-LEAVE-FEEDBACK": "Leave feedback",
      "TITLE-FEEDBACK": "Leave Feedback",
      "PLACEHOLDER-COMMENT": "Leave a comment here",
      "BUTTON-SUBMIT-FEEDBACK": "Submit feedback",
      "INFO-THANKS-FOR-FEEDBACK": "Thanks for your feedback",
      "BUTTON-RESTART": "Restart",
      "COPYRIGHT-INFO": "The game is a part of <1>Make Equals</1> project <3>Allt vi inte pratar om</3> which has been produced with support by <5>Svenska Postkodsstiftelsen</5>",
      "INFO-BEFORE-WE-START": "Tips and practical information",
      "INFO-BEFORE-VIEW": {
        "INTRODUCTION": "<h1>Hello!</h1><p>Welcome to a conversation about everything you would otherwise not talk about! The questions are aimed at everyone who identifies as boys and men. With that said, it also works to use the questions in mixed-gender groups, just for girls, or for other genders. In those cases, it is good to know that the questions are designed for and are aimed at boys and men, so they should be reformulated to match the group.</p><p>Here are some points that may be helpful before, during, and after the conversation.</p>",
        "BEFORE-THE-TALK": "<h2>Before the conversation</h2><ul><li>Agree on the fact that what you discuss in the group is done in confidence, and that it stays in the room.</li><li>Decide how the conversation should be structured. It can be in pairs or in groups of 3-5 people. You may be sitting around a table, talking in the locker room, during a coffee break, or online. Do what feels good and suits you best.</li><li>If the conversation takes place in a group, appoint a conversation leader who is responsible for the conversation and who makes sure that you stick to the topic, do not interrupt each other, and treat each other with respect and consideration. You can also change conversation leaders during the conversation.</li><li>Agree on how to decide who will speak. For example, you can use conversation rounds where everyone is given the opportunity to talk, raising hands, or other methods. The important thing is to find a method that everyone is comfortable with. A tip is to start with around where everyone is given the same opportunity to answer in peace and quiet, then open the dialogue for people to raise their hands and speak.</li></ul>",
        "DURING-THE-TALK": "<h2>During the conversation</h2><ul><li>Use yourself as the starting point of the conversation. Sometimes it is easy to talk about others, or boys and men in general. By talking in the first person, about yourself, it becomes easier to keep the conversation about your own experiences.</li><li>When listening to other people's stories, try to assume that we all have different preferences and experiences. Not everyone will necessarily like you or have exactly the same experience as you. Try not to judge others, and do not directly question other people—but if something is unclear, you can ask open-ended questions to clarify. By doing so, you also contribute to an open, welcoming and inclusive conversation environment.</li><li>Try to interpret others kindly. It takes courage and can be hard to share your experiences. By interpreting kindly, we assume that everyone means well, and that through the conversation, we develop together.</li><li>Try to create an atmosphere where everyone feels safe to ask if there is something they do not understand or something that feels unclear. It's not about saying the “right” things, but about sharing experiences, listening to others, and supporting each other.</li><li>Take one question at a time, and try not to stress. You do not have to go through the themes in any particular order, but choose what feels interesting and relevant to you. Based on each theme, it can be beneficial to follow the order of the questions. That being said, if any question feels irrelevant or particularly difficult/uncomfortable, you can always just skip that question.</li></ul>",
        "AFTER-THE-TALK": "<h2>After the conversation</h2><ul><li>Feel free to end the conversation with a round where everyone can briefly share how it felt to have a conversation about these questions:</li><ul><li>How did it feel to talk about these topics?</li><li>Did something feet difficult or uncomfortable?</li><li>What will you take with you from the conversation?</li><li>Would you like to add or comment on something that came up during the conversation? (skip this if you are running out of time).</p><li>Are there any concrete changes you want to make in your life that you discovered during the conversation?</li><li>Would you like to continue having this type of conversation?</li></ul><li>If you want to continue these conversations, you can start a group chat or something similar where you can keep in touch and schedule new conversations.</p><li>Feel free to remind each other that everything said during the conversation stays in the room.</p><li>Encourage others to arrange meetings based on \"Everything we don’t talk about - recreation & sports\". For example, you can post a photo from the session on social media using the hashtag #alltviintepratarom and #killmiddag. Just important to check and make sure that everyone wants to be in the picture before sharing it. Of course, nothing is shared about what was said during the meeting.</li></ul>",
        "OUTRO": "<p><strong>The questions are aimed at everyone who identifies as boys and men. It also works to use these questions in mixed-gender groups, but then it is good to know that the questions are designed for and are aimed at boys and men.</strong></p>",
        "MORE-INFO": "For more information:"
      },
      "START-QUESTIONS-HEADLINE": "Warm-up statements",
      "START-QUESTIONS-INFO": "Everyone answers yes or no if the statement applies to you without commenting or asking about anyone else's response.",
      "ALT-LOGOTYPE": "Logotype, Make Equal, to the start page",
      "ALT-ILLUSTRATED-MAN": "Illustration of a man wearing a t-shirt with Everything we don't talk about printed on it",
      "BUTTON-BACK": "Go back",
      "RATING-STAR-1": "Rate the app with one star.",
      "RATING-STAR-2": "Rate the app with two stars.",
      "RATING-STAR-3": "Rate the app with three stars.",
      "RATING-STAR-4": "Rate the app with four stars.",
      "RATING-STAR-5": "Rate the app with five stars.",
      "RATING-SET-START-1": "One out of five stars is now set.",
      "RATING-SET-START-2": "Two out of five stars are now set.",
      "RATING-SET-START-3": "Three out of five stars are now set.",
      "RATING-SET-START-4": "Four out of five stars are now set.",
      "RATING-SET-START-5": "Five out of five stars are now set.",
    }
  }
};

