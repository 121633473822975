import React, { useState, ChangeEvent, useEffect } from 'react';
import Firebase, { withFirebase } from '../../components/Firebase';
import { AdminPage, LoadingSpinner, TrashIcon } from '../../components';
import { ICategoryData, IAreaData } from '../../components/Firebase/firebase';

interface IAdminAddEditQuestionViewProps {
  firebase: Firebase;
  history: { goBack(): void };
  match: { params: { id: string } };
}

const AdminAddEditQuestionView = (props: IAdminAddEditQuestionViewProps): React.ReactElement => {

  const [categories, setCategories] = useState<ICategoryData[] | undefined>(undefined);
  const [swedishTitle, setSwedishTitle] = useState<string>("");
  const [englishTitle, setEnglishTitle] = useState<string>("");
  const [editId, setEditId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newQuestionCategories, setNewQuestionCategories] = useState<string[]>([]);
  const [newSubQuestionSwedish, setNewSubQuestionSwedish] = useState<string>("");
  const [newSubQuestionEnglish, setNewSubQuestionEnglish] = useState<string>("");
  const [newSubQuestions, setNewSubQuestions] = useState<{ en: string, se: string }[]>([]);
  const { id } = props.match.params;
  const { firebase } = props;

  useEffect(() => {
    setEditId(id);
    firebase.getAllCategories().then(categories => {
      setCategories(categories);
    });
    if (id) {
      firebase.getQuestion(id).then(data => {
        if (data.subquestions) {
          const newSubQ: { en: string, se: string }[] = [];
          (data.subquestions as { en: [], se: [] }).se.forEach((subQ, index: number) => {
            newSubQ.push({ se: subQ, en: (data.subquestions as { en: [], se: [] }).en[index] })
          })
          setNewSubQuestions(newSubQ);
        }
        setNewQuestionCategories(data.categories as string[]);
        setSwedishTitle(data.se as string);
        setEnglishTitle(data.en as string);
      });
    }
  }, [firebase, id]);

  const cancel = () => {
    props.history.goBack();
  }

  const save = () => {
    setIsLoading(true);
    if (!editId) {
      firebase.saveQuestion(swedishTitle, englishTitle, newSubQuestions, newQuestionCategories).then(() => {
        setIsLoading(false);
        props.history.goBack();
      });
    } else {
      firebase.editQuestion(editId, swedishTitle, englishTitle, newSubQuestions, newQuestionCategories).then(() => {
        setIsLoading(false);
        props.history.goBack();
      });
    }
  }

  const onChangeSwedishTitle = (e: ChangeEvent<HTMLInputElement>) => { setSwedishTitle(e.currentTarget.value); }
  const onChangeEnglishTitle = (e: ChangeEvent<HTMLInputElement>) => { setEnglishTitle(e.currentTarget.value); }
  const changeNewSubQuestionSwedish = (e: ChangeEvent<HTMLInputElement>) => { setNewSubQuestionSwedish(e.currentTarget.value); }
  const changeNewSubQuestionEnglish = (e: ChangeEvent<HTMLInputElement>) => { setNewSubQuestionEnglish(e.currentTarget.value); }

  const setQuestionCategory = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setNewQuestionCategories([...newQuestionCategories, e.currentTarget.value]);
    } else {
      setNewQuestionCategories(newQuestionCategories.filter(category => { return category !== e.currentTarget.value }));
    }
  }

  const addNewSubQuestion = (): void => {
    setNewSubQuestions([...newSubQuestions, { en: newSubQuestionEnglish, se: newSubQuestionSwedish }]);
    setNewSubQuestionSwedish("");
    setNewSubQuestionEnglish("");
  }

  const removeSubQuestion = (index: number): void => {
    const tmpArray = [...newSubQuestions];
    tmpArray.splice(index, 1);
    setNewSubQuestions(tmpArray);
  }

  const orderCategories = (categories: ICategoryData[]): ICategoryData[] => {
    return categories.sort((cat1, cat2) => {
      return ((cat1.areas as IAreaData[]).sort()[0].se as string).localeCompare((cat2.areas as IAreaData[]).sort()[0].se as string)
    })
  }

  return (
    <AdminPage activeItem={2}>
      {!isLoading && categories !== undefined ?
        <>
          {editId ? <h2>Ändra fråga</h2> : <h2>Lägg till fråga</h2>}
          <div>
            <label>Svensk fråga</label>
            <input type="text" placeholder="Svensk fråga" value={swedishTitle} onChange={onChangeSwedishTitle} />
            <label>Engelsk fråga</label>
            <input type="text" placeholder="Engelsk fråga" value={englishTitle} onChange={onChangeEnglishTitle} />
            <div>
              {categories && orderCategories(categories).map((category, index) => {
                return (
                  <div key={category.id}>
                    <input type="checkbox" id={`chkBox-${index}`} value={category.id} onChange={setQuestionCategory} checked={newQuestionCategories.includes(category.id)} />
                    <label htmlFor={`chkBox-${index}`}>
                      <b>{category.se}</b>&nbsp;
                      ({category.areas && category.areas.map( area => area.se ).join(", ")})
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="sub-questions-section">
              <label>Underfråga, svensk översättning</label>
              <input type="text" placeholder="Underfråga på Svenska" value={newSubQuestionSwedish} onChange={changeNewSubQuestionSwedish} />
              <label>Underfråga, engelsk översättning</label>
              <input type="text" placeholder="Underfråga på Engelska" value={newSubQuestionEnglish} onChange={changeNewSubQuestionEnglish} />
              <button className="form-button" onClick={addNewSubQuestion} disabled={newSubQuestionSwedish.length === 0}>Lägg till underfråga</button>
            </div>
            {newSubQuestions.length > 0 &&
              <div className="spaceAbove">

                <h4>Underfrågor:</h4>
                <table>
                  <tbody>
                    <tr>
                      <th>Svenska</th>
                      <th colSpan={2}>Engelska</th>
                    </tr>
                    {newSubQuestions.map((subQuestion, index: number) => {
                      return (
                        <tr key={index}><td style={{ width: '50%' }}>{subQuestion.se}</td><td style={{ width: '50%' }}>{subQuestion.en}</td><td><button className="iconButton" onClick={() => { removeSubQuestion(index) }}><TrashIcon /></button></td></tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            }
          </div>
          <div className="form-button-container">
            <button className="form-button" onClick={cancel}>Avbryt</button>
            <button className="form-button" onClick={save} disabled={swedishTitle.length === 0 || newQuestionCategories.length === 0}>Spara</button>
          </div>
        </>
        :
        <LoadingSpinner />
      }
    </AdminPage>
  );
}

export default withFirebase(AdminAddEditQuestionView);